@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap');

// Bootstrap mixins and functions
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/functions";

// Change variables here
@import "volt/variables";

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

// Vendor
@import "volt/vendor";

// volt mixins & functions
@import "volt/mixins";
@import "volt/functions";

// Utilities
@import "volt/reboot";
@import "volt/utilities";

// Layout
@import "volt/layout";

// Components
@import "volt/components";

// write your custom styles here!

.logo{
  padding: 5px;
  margin-bottom: 10px;
}

.cust-navbar {
    border-bottom: 2px solid rgba(74, 80, 115, 0.5);
}

input.table-search {
  width: 30%;
  min-width: 200px;
  border-radius: 10px;
  border: 2px solid #14877c;
  padding: 10px;
  font-size: 14px;
}
  
table {
  margin: 20px 0;
}

thead {
  font-size: 16px;
}

th {
  text-align: left;
}
td {
  text-align: left;
}

.error-message {
  text-align: center;
  margin-top: 10%;
  color: red;
  font-weight: bold;
}

.loading {
  text-align: center;
  margin-top: 10%;
}

.cust-button {
  background-color: #5bc0de;
  border-color: #46b8da;
  font-size: 16px;
  color: #000;
}

/* Dashboard cards */
.dashboard-stat.purple-plum {
  background-color: #8775a7;
}

.dashboard-stat {
  display: block;
  margin-bottom: 25px;
  overflow: hidden;
  border-radius: 2px;
}

.dashboard-stat, .dashboard-stat2, .page-md .widget-bg-color-white, .page-md .widget-blog, .page-md .widget-carousel, .page-md .widget-map, .page-md .widget-progress-element, .page-md .widget-socials {
  box-shadow: 0 2px 3px 2px rgba(0,0,0,.03);
}

.dashboard-stat .visual {
  width: 80px;
  height: 80px;
  display: block;
  float: left;
  padding-top: 10px;
  padding-left: 15px;
  margin-bottom: 15px;
  font-size: 35px;
  line-height: 35px;
}

.dashboard-stat .details {
  right: 15px;
  padding-right: 15px;
}

.dashboard-stat.purple-plum .details .number {
  color: #fff;
}

.dashboard-stat .details .number {
  padding-top: 25px;
  text-align: right;
  font-size: 34px;
  line-height: 36px;
  letter-spacing: -1px;
  margin-bottom: 0;
  font-weight: 300;
}

.dashboard-stat.purple-plum .details .desc {
  color: #fff;
  opacity: 1;
}

.dashboard-stat .details .desc {
  text-align: right;
  font-size: 16px;
  letter-spacing: 0;
  font-weight: 300;
}

.dashboard-stat.purple-plum .visual>span {
  color: #fff;
  opacity: .1;
}

.dashboard-stat .visual>span {
  margin-left: -35px;
  font-size: 110px;
  line-height: 110px;
}

.dashboard-stat.blue-madison {
  background-color: #578ebe;
}

.dashboard-stat.blue-madison .details .number {
  color: #FFF;
}

.dashboard-stat.blue-madison .details .desc {
  color: #FFF;
  opacity: 1;
}

.dashboard-stat.blue-madison .visual>span {
  color: #FFF;
  opacity: .1;
}

.dashboard-stat.red-intense {
  background-color: #933d3c;
}

.dashboard-stat.red-intense .details .number {
  color: #fff;
}

.dashboard-stat.red-intense .details .desc {
  color: #fff;
  opacity: 1;
}

.dashboard-stat.red-intense .visual>span {
  color: #fff;
  opacity: .1;
}

.dashboard-stat.green-haze {
  background-color: #44b6ae;
}

.dashboard-stat.green-haze .details .number {
  color: #FFF;
}

.dashboard-stat.green-haze .details .desc {
  color: #FFF;
  opacity: 1;
}

.dashboard-stat.green-haze .visual>span {
  color: #FFF;
  opacity: .1;
}
/* Dashboard cards */

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: .3rem;
}